import React from 'react'
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

function HowItWorks() {
  return (
    <>
      <Layout type="how-it-works"/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lenx.ai</title>
      </Helmet>
    </>
  )
}

export default HowItWorks
